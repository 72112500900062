export const REVIEW_DASHBOARD = 'REVIEW_DASHBOARD';
export const PENDING_DASHBOARD = 'PENDING_DASHBOARD';
export const EXPORT_STATUS = 'EXPORT_STATUS';
export const EXPORT_BATCH = 'EXPORT_BATCH';
export const DELETE_STATUS = 'DELETE_STATUS';
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const ADMIN_UPLOAD_DASHBOARD = 'ADMIN_UPLOAD_DASHBOARD';
export const OPEN_CASES_DASHBOARD = 'OPEN_CASES_DASHBOARD';
export const IS_ADMIN = 'IS_ADMIN';
export const CONTRACT_LIST = 'CONTRACT_LIST';
export const LTAPE_LIST = 'LTAPE_LIST';
export const FOLDERS_LIST = 'FOLDERS_LIST';
export const ZIP_FILE = 'ZIP_FILE';
export const LOAN_TAPE = 'LOAN_TAPE';
export const STATUS = 'STATUS';